export const scienceAccordionInfo = [
  {
    sectionHeader: "How do you determine beef tenderness?",
    accordionContent: `The scientific technique for measurements of beef tenderness is the Warner- Bratzler Shear Force 
instrument.  The Warner-Bratzler instrument records bite pressure and has been foundational in 
correlating DNA marker technology and beef tenderness.  There is a highly significant and favorable 
difference between Tenet™ and normal American beef.  Beef tenderness has nothing to do with 
beef fat.`,
  },
  {
    sectionHeader: "What is the process used for certification?",
    accordionContent: `1. DNA is extracted from blood samples. <br />
2. The cattle producer buys DNA cards and applies 3 or 4 drops of blood to the specialized blotter paper within the sample cards. The cards are mailed to Callipyge Genetics. <br />
3. Multistep detailed lab work is completed and analyzed. <br />
4. Cattle producers are notified if the sampled animals are Tenet™. <br />
5. A visual analysis is produced, indicating the presence of identified traits <br />
6. Callipyge Genetics evaluates results and produces tenderness certifications`,
  },
  {
    sectionHeader: "Is certified tender beef natural and non-GMO?",
    accordionContent:
      "Tenet™ has nothing to do with GMO and is not treated in any way.  We use the most modern " +
      "DNA marker technology, much like several human companies screening for human ancestry.  Again, Tenet™" +
      "is not treated and is not GMO. Tenet™ is naturally selected using DNA analysis.",
  },
  {
    sectionHeader: `How can I use these certifications to increase my herd's tenderness levels?`,
    accordionContent:
      "Cattle producers can increase beef quality and tenderness by selectively breeding " +
      "with Tenet™ certified sires.",
  },
  {
    sectionHeader: "How long will it take to breed tenderness into my herd?",
    accordionContent: `Under normal conditions a cattle herd can increase quality with every breeding by using Tenet™ 
sires.  Moving from normal American beef tenderness to Tenet™ tenderness can be accomplished 
in two generations of selection and breeding to Tenet™ certified sires.  There are Tenet™ sires 
available in most AI sire catalogs.  We are working to get Tenet™ sires registered and marked in 
future catalogs.  We are working with artificial insemination companies to denote Tenet™ sires. Tenet™ sires are available for natural breeding.  Tenet™ embryos are also available for faster 
quality improvement.`,
  },
  {
    sectionHeader: "What if I still want fat content and marbling?",
    accordionContent:
      "Tenet™ certified tenderness is not correlated to fat and marbling. " +
      "Tenet™ carcasses will be USDA quality graded as select, choice or " +
      "prime as every beef carcasses.  A USDA select graded Tenet™ animal will be " +
      "significantly higher quality and higher tenderness than USDA prime graded non-" +
      "Tenet™ beef. Tenet™ certifications are ideal for grass finished and " +
      "lighter finished highest quality beef.",
  },
  {
    sectionHeader: "Do you certify Wagyu beef?",
    accordionContent: `Yes. American Wagyu cattle are Japanese Wagyu cattle that are cross-bred with American Angus 
stock and are known for high levels of fat and marbling but have the same tenderness issues as 
normal American beef.  Wagyu can be Tenet™ certified to increase quality and tenderness just as 
any other Bos Taurus breed of cattle.`,
  },
]
