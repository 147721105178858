import React from "react"

export const ScienceTechnologyInfo = {
  sectionHeader: "DNA MARKER TECHNOLOGY",
  sectionInfo: [
    {
      displayText: `Frank Hendrix, founder and CEO of Callipyge Genetics, began researching meat tenderness over thirty years ago. He discovered the Callipyge gene and how it is inherited in 1994. Animals with the Callipyge gene produced significantly more meat (30%), but several cuts of meat were tough. Hendrix passed his research on to scientists in human research fields. The consequence was a new field of genetic study named epigenetics and a Nobel prize for those scientists. While his research had a positive scientific outcome, the issue of meat tenderness was still unanswered. Hendrix hypothesized the answer lay at the genetic level. Over several decades of selective breeding for tenderness, he identified a series of DNA markers that accurately and effectively select for beef tenderness.`,
    },
    {
      displayText: `A dedicated, award-winning cattleman himself, Hendrix created Tenet™ to introduce scientific standards to accurately identify and select cattle for tenderness for the first time. He proved tenderness is an inherited trait. According to many USDA studies of beef consumers, the number one problem in the industry is tenderness. Excessive fat is wasteful and expensive to the entire cattle industry. Naturally selected beef tenderness through Tenet™ certification removes the top consumer concern and has a positive impact on both feedlot and grass-finished beef. Environmentally, less time is required in a feedlot, reducing the cost of finishing for marbling/fat, and confinement related health and wellness concerns. The benefit of tenderness to the grass-finished industry is a game changer.`,
    },
    {
      displayText: `Tenet™ certified cattle and beef represents the new quality paradigm and the highest level of beef quality in existence. Tenet™ is good for the consumer, good for the producer, good for the animal, and good for the environment.`,
    },
  ],
}
