import scienceHero from "../images/TheScience_Header-optimized.jpg"
import classes from "../components/HeroSection/HeroSection.module.css";
import indexBlackText from "../images/black-text-section.jpg"
import scienceParallax from "../images/TheScience_Parallax.jpg"

export const scienceContent = {
    heroSection: {
        sectionId: "The Science",
        background: scienceHero,
        heroImageClass: "headerImageClass",
        imageFile: "cow.png",
        alt: "cow",
        headerText: "THE SCIENCE",
        displayText: "THE FIRST NATURALLY SELECTED, DNA AUTHENTICATED BEEF TENDERNESS RATING",
        bottomImage: "TheScience_Beef.png",
        bottomImageClass: classes.scienceImageWrapper
    },
    blackTextVideoSection: {
        sectionId: "natures-best-beef",
        background: indexBlackText,
        displayText: [
            {
                text: "NATURE'S BEST BEEF",
                color: "rgba(255, 255, 255, 1)"
            },
        ],
    },
    parallax: {
        sectionId: 'the-science-parallax',
        background: scienceParallax,
        alt: 'parallax-one-image',
    },
}
