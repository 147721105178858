import React from "react"
import Image from "../Image/image"
import FullWidthYellowDivider from "../YellowDivider/FullWidthYellowDivider"
import SmallYellowDivider from "../YellowDivider/SmallYellowDivider"
import classes from "./ScienceTechnology.module.css"
import { ScienceTechnologyInfo } from "./ScienceTechnologyInfo"

const ScienceTechnology = () => {
  return (
    <React.Fragment>
      <section className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          <h6 className={classes.sectionHeader}>
            {ScienceTechnologyInfo.sectionHeader}
          </h6>
          <div className={classes.dividerContainer}>
            <SmallYellowDivider />
          </div>
          <div className={classes.scienceInfoContainer}>
            <div className={classes.scienceInfoParagraphContainer}>
              {ScienceTechnologyInfo.sectionInfo.map(item => (
                <p className={classes.sectionText}>{item.displayText}</p>
              ))}
            </div>
            <div className={classes.imageContainer}>
              <Image filename="ScienceFounder" alt="Frank Hendrix" />
            </div>
          </div>
          <div className={classes.subTextContainer}>
            <p className={classes.sectionText}>
              {ScienceTechnologyInfo.sectionSubText}
            </p>
          </div>
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  )
}

export default ScienceTechnology
