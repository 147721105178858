import React, { useRef, useState } from "react"
import Chevron from "./Chevron"
import "./ScienceAccordion.css"

const ScienceAccordion = ({ title, content }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const [setRotate, setRotateState] = useState("accordion__icon")

  const contentRef = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${contentRef.current.scrollHeight}px`
    )
    setRotateState(
      setActive === "active" ? "accordionIcon" : "accordionIcon rotate"
    )
  }

  return (
    <div className="accordionSection">
      <div className="accordionWrapper">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <div className="titleWrapper">
            <p className="accordionTitle">{title}</p>
          </div>
          <div className="iconWrapper">
            <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
          </div>
        </button>
        <div
          ref={contentRef}
          style={{ maxHeight: `${setHeight}` }}
          className="accordionContent"
        >
          <p
            className="accordionText"
            dangerouslySetInnerHTML={{ __html: content }}
          ></p>
        </div>
      </div>
    </div>
  )
}

export default ScienceAccordion
