import React from 'react';
import FullWidthYellowDivider from '../YellowDivider/FullWidthYellowDivider';
import ScienceAccordion from './components/ScienceAccordion';
import { scienceAccordionInfo } from './ScienceAccordionInfo';
import classes from './ScienceAccordionSection.module.css';

const ScienceAccordionSection = () => {
  return (
    <React.Fragment>
      <FullWidthYellowDivider />
      <section className={classes.sectionContainer}>
        <div className={classes.sectionWrapper}>
          {scienceAccordionInfo.map(section => (
            <ScienceAccordion
              title={section.sectionHeader}
              content={section.accordionContent}
            />
          ))}
        </div>
      </section>
      <FullWidthYellowDivider />
    </React.Fragment>
  );
};

export default ScienceAccordionSection;
