import React from 'react';
import BlackTextVideoSection from '../components/BlackTextVideoSection/BlackTextVideoSection';
import HeroSection from '../components/HeroSection/HeroSection';
import ScienceAccordionSection from '../components/ScienceAccordion/ScienceAccordionSection';
import ScienceTechnology from '../components/ScienceTechnology/ScienceTechnology';
import { scienceContent } from '../content/theScience';
import ParallaxSection from '../components/ParallaxSection/ParallaxSection'
import SEO from '../components/seo'

const TheScience = () => {
  return (
    <React.Fragment>
      <SEO title={"The Science"} />
      <HeroSection {...scienceContent.heroSection} />
      <BlackTextVideoSection {...scienceContent.blackTextVideoSection} />
      <ParallaxSection {...scienceContent.parallax} />
      <ScienceAccordionSection />
      <ScienceTechnology />
    </React.Fragment>
  );
};

export default TheScience;
